import { Button, FormControl } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface IModalProps {
  id?: string;
  show: boolean;
  title: React.ReactNode;
  inputValue: string;
  onInputChange: (value: string) => void;
  onConfirm: () => void;
  onCancel: () => void;
  placeholder: string;
  confirmBtnLabel?: string;
  disabled?: boolean;
  as?: 'input' | 'textarea' | any;
  autoFocus?: boolean;
  disableCnfBtn?: boolean;
  formCtrlStyle?: { [key: string]: string };
  maxLength?: number;
  footerBtnNotNeeded?: boolean;
  cancelBtnLabel?: string;
}

const FormModal = ({
  id,
  show,
  title,
  inputValue,
  onInputChange,
  onConfirm,
  onCancel,
  placeholder,
  confirmBtnLabel,
  disabled,
  autoFocus,
  as,
  disableCnfBtn,
  formCtrlStyle,
  maxLength,
  footerBtnNotNeeded,
  cancelBtnLabel,
}: IModalProps) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl
          id={id}
          as={as}
          type="text"
          value={inputValue}
          onChange={(e) => onInputChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          style={formCtrlStyle}
          maxLength={maxLength}
        />
      </Modal.Body>
      {!disabled && !footerBtnNotNeeded && (
        <Modal.Footer>
          <Button variant="outline-primary" onClick={onCancel}>
            {cancelBtnLabel ?? 'Cancel'}
          </Button>
          <Button
            disabled={disableCnfBtn}
            onClick={onConfirm}
            className={`${disableCnfBtn ? 'text-white' : 'text-white'}`}
          >
            {confirmBtnLabel ?? 'Confirm'}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default FormModal;
