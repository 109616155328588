import { Button } from 'react-bootstrap';

export const GridTableHeader = ({
  tableColumns,
  onClick,
  title,
  reporteeSelected,
  disabled,
}) => {
  return (
    <div className="row border-bottom border-dark m-0">
      {reporteeSelected ? (
        <Button
          variant="link"
          className="text-decoration-none d-flex align-items-center justify-content-left ps-3 mb-1 fw-fw-semibold col-4 text-primary"
          onClick={onClick}
          disabled={disabled}
        >
          <i title={title} className="bi bi-sticky me-2"></i>
          {title}
        </Button>
      ) : (
        <div className="text-decoration-none d-flex align-items-center justify-content-left ps-3 mb-1 fw-fw-semibold col-4 text-primary gap-1"></div>
      )}

      {tableColumns.map((column, columnIndex) => (
        <div
          className="p-2 fw-bold col mb-1"
          key={`${column.name}-${columnIndex}`}
          style={{
            fontSize: '.75rem',
            // backgroundColor:
            //   columnIndex === 0 || columnIndex === 6 ? '#F6F189' : '',
          }}
        >
          {column.name}
        </div>
      ))}
    </div>
  );
};
